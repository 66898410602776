.banner{
    background-color: #CCC;
    /* height: 250px; */
    border-bottom: 4px #00A650 solid;
    position: relative;
}

.subtitle{
    padding-top: 120px;
    font-size: 1em;

}

.contatoComercial{
    color: #00A650;
    margin-top: 50px;
}

@media screen and (min-width: 1000px){
    .contatoComercial{
        display: flex;
        flex-direction: column;
        height: 400px;
        color: #00A650;
        justify-content: center;
        margin-left: 50px;
    }
}

@media screen and (max-width: 768px){
    .banner img{
        height: 100px;
        width: 100%;
        object-fit: cover;
    }
}