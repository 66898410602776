body{
    padding-bottom: 200px;
}

main{
    margin-top: 50px;
    color: #00A650;
    font-size: 1.2em;
}

main p{
    margin: 40px 0;
}

.container-fluid{
    overflow: hidden;
}

